import {ethers} from 'ethers';
import qs from 'qs';

export default class Client {

  constructor(options) {
    this.options = options;
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.explorer = new Explorer(options.explorer);
  }

  networkId() {
    return this.options.id;
  }

  getUtils() {
    return ethers.utils
  }

  getSigner() {
    return this.provider.getSigner()
  }

  /**
   * general contract method
   * @param address
   * @param abi
   * @param funcName : contract function name
   * @param args : contract function params
   * @returns contract function execute result
   */
  async runContractTransactionFunc(address, abi, funcName, ...args) {
    const contract = new ethers.Contract(address, abi, this.provider.getSigner());
    return contract.functions[funcName](...args)
  }

  /**
   * general contract method
   * @param address
   * @param abi
   * @param funcName : contract function name
   * @param args : contract function params
   * @returns query result
   */
  async queryContract(address, abi, funcName, ...args) {
    const provider = new ethers.providers.JsonRpcProvider(this.options.url);
    const contract = new ethers.Contract(address, abi, provider);
    return contract.functions[funcName](...args)
  }

  async populateContract(address, abi, method, ...args) {
    const contract = new ethers.Contract(address, abi, this.provider.getSigner());
    return await contract.populateTransaction[method](...args);
  }

  async getTransactions(address, page, size) {
    const result = await this.explorer.getHistory(address, page, size);
    return {
      length: 0,
      list: result.result
    };
  }

  formatEther(wei) {
    return ethers.utils.formatEther(wei)
  }

  // eslint-disable-next-line class-methods-use-this
  parseUnits(ether, num) {
    return ethers.utils.parseUnits(ether, num);
  }

  // eslint-disable-next-line class-methods-use-this
  formatUnits(ether, num) {
    return ethers.utils.formatUnits(ether, num);
  }

  // eslint-disable-next-line class-methods-use-this
  parseEther(ether) {
    return ethers.utils.parseEther(ether);
  }
}

class Explorer {
  url;

  constructor(url) {
    this.url = url;
  }

  async getHistory(address, page = 0, size = 10) {
    const query = {
      module: 'account',
      action: 'txlist',
      address,
      startblock: 0,
      endblock: 99999999,
      page: page + 1,
      offset: size,
      sort: 'desc'
    };

    const res = await fetch(`${this.url}?${qs.stringify(query)}`);
    const result = await res.json();
    return result;
  }
}
